import { Suspense, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { Button } from "../components/Button";
import { Tag } from "../components/Tag";

function WrappedTool(props) {
  const { tool } = props;
  const [ proceed, setProceed ] = useState(false);

  return (
    <>
      <Helmet>
        <title>good.tools · {tool.title}</title>
        <meta name="description" content={tool.description} />
        <meta name="keywords" content={tool.tags.join(",")} />
      </Helmet>
      <div className="mb-4 pb-2 border-b dark:border-zinc-700">
        <div className="text-xl">
          {tool.title}
        </div>
        <div className="text-xs">{tool.description}</div>
      </div>
      {tool.warning && !proceed ? (
        <div>
          <tool.warning />
          <p className="mt-4">Proceed at your own risk.</p>
          <Button onClick={() => setProceed(true)} className={"mt-4"}>Continue</Button>
        </div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <tool.component />
        </Suspense>
      )}
      {typeof tool.dependencies !== "undefined" && (
        <div className="border-t border-zinc-900/5 mt-6 pt-3 dark:border-white/5 text-zinc-600 dark:text-zinc-400">
          {tool.online && (
            <div className="text-xs flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
              </svg>
              This is an online tool, the data you submit gets processed on a remote server.
            </div>
          )}
          <div className="text-xs">
            <div className="inline space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1 inline">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
              </svg>
              Built using
              {tool.dependencies.map((d, i) => (
                <Tag key={`tag-${i}`} color="sky" type="a" target="_blank" rel="noopener" href={d.url}>{d.name}</Tag>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WrappedTool