import { useRef } from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion, useIsPresent } from 'framer-motion'

import { useIsInsideMobileNavigation } from '../components/MobileNavigation'
import { Tag } from '../components/Tag'
import { remToPx } from '../lib/remToPx'
import { Link, useLocation } from 'react-router-dom'
import { tools } from '../tools'
import packageJson from '../../package.json';

function useInitialValue(value, condition = true) {
  let initialValue = useRef(value).current
  return condition ? initialValue : value
}

function NavLink({ href, tag, active, isAnchorLink = false, children }) {
  return (
    <Link
      to={href}
      aria-current={active ? 'page' : undefined}
      className={clsx(
        'flex justify-between gap-2 py-1 pr-3 text-sm transition',
        isAnchorLink ? 'pl-7' : 'pl-4',
        active
          ? 'text-zinc-900 dark:text-white'
          : 'text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white'
      )}
    >
      <span className="truncate">{children}</span>
      {tag && (
        <Tag variant="small" color="zinc">
          {tag}
        </Tag>
      )}
    </Link>
  )
}

function VisibleSectionHighlight({ group, pathname }) {
  let [sections, visibleSections] = useInitialValue(
    [
      [],
      [],
    ],
    useIsInsideMobileNavigation()
  )

  let isPresent = useIsPresent()
  let firstVisibleSectionIndex = Math.max(
    0,
    [{ id: '_top' }, ...sections].findIndex(
      (section) => section.id === visibleSections[0]
    )
  )
  let itemHeight = remToPx(2)
  let height = isPresent
    ? Math.max(1, visibleSections.length) * itemHeight
    : itemHeight
  let top =
    group.links.findIndex((link) => link.href === pathname) * itemHeight +
    firstVisibleSectionIndex * itemHeight

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      className="absolute inset-x-0 top-0 bg-zinc-800/2.5 will-change-transform dark:bg-white/2.5"
      style={{ borderRadius: 8, height, top }}
    />
  )
}

function ActivePageMarker({ group, pathname }) {
  let itemHeight = remToPx(2)
  let offset = remToPx(0.25)
  let activePageIndex = group.links.findIndex((link) => link.href === pathname)
  let top = offset + activePageIndex * itemHeight

  return (
    <motion.div
      layout
      className="absolute left-2 h-6 w-px bg-blue-500"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      style={{ top }}
    />
  )
}

function NavigationGroup({ group, className }) {
  // If this is the mobile navigation then we always render the initial
  // state, so that the state does not change during the close animation.
  // The state will still update when we re-open (re-render) the navigation.
  let isInsideMobileNavigation = useIsInsideMobileNavigation()
  let [router, sections] = useInitialValue(
    [useLocation(), []],
    isInsideMobileNavigation
  )

  let isActiveGroup =
    group.links.findIndex((link) => link.href === router.pathname) !== -1

  return (
    <li className={clsx('relative mt-6', className)}>
      <motion.h2
        layout="position"
        className="text-xs font-semibold text-zinc-900 dark:text-white"
      >
        {group.title}
      </motion.h2>
      <div className="relative mt-3 pl-2">
        <AnimatePresence initial={!isInsideMobileNavigation}>
          {isActiveGroup && (
            <VisibleSectionHighlight group={group} pathname={router.pathname} />
          )}
        </AnimatePresence>
        <motion.div
          layout
          className="absolute inset-y-0 left-2 w-px bg-zinc-900/10 dark:bg-white/5"
        />
        <AnimatePresence initial={false}>
          {isActiveGroup && (
            <ActivePageMarker group={group} pathname={router.pathname} />
          )}
        </AnimatePresence>
        <ul className="border-l border-transparent">
          {group.links.map((link) => (
            <motion.li key={link.href} layout="position" className="relative">
              <NavLink href={link.href} active={link.href === router.pathname} tag={link.tag}>
                {link.title}
              </NavLink>
              <AnimatePresence mode="popLayout" initial={false}>
                {link.href === router.pathname && sections.length > 0 && (
                  <motion.ul
                    role="list"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { delay: 0.1 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15 },
                    }}
                  >
                    {sections.map((section) => (
                      <li key={section.id}>
                        <NavLink
                          href={`${link.href}#${section.id}`}
                          tag={section.tag}
                          isAnchorLink
                        >
                          {section.title}
                        </NavLink>
                      </li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </motion.li>
          ))}
        </ul>
      </div>
    </li>
  )
}

export const navigation = [
  {
    title: '',
    links: [
      { title: 'Home', href: '/' },
    ],
  },
  {
    title: 'All Tools',
    links: tools.map(t => {
      return {
        title: t.title,
        href: t.href,
        tag: t.tag
      }
    }),
  },
  // {
  //   title: 'PDF',
  //   links: [
  //     { title: 'Fill & Sign', href: '/pdf/fill-and-sign' },
  //     { title: 'Extract Pages', href: '/pdf/extract-pages' },
  //     { title: 'PDF to Image', href: '/pdf/pdf-to-image' },
  //   ],
  // },
  // {
  //   title: 'Image Manipulation',
  //   links: [
  //     { title: 'Color Picker', href: '/image/color-picker' },
  //     { title: 'Remove Background', href: '/image/remove-background' },
  //     { title: 'Format Converter', href: '/image/format-converter' },
  //   ],
  // },
  // {
  //   title: 'Parsers & Formatters',
  //   links: [
  //     { title: 'JSON', href: '/json' },
  //     { title: 'XML', href: '/xml' },
  //   ],
  // },
  // {
  //   title: 'Encoders & Decoders',
  //   links: [
  //     { title: 'Hex', href: '/hex' },
  //     { title: 'Base64', href: '/base64' },
  //     { title: 'URL', href: '/url' },
  //     { title: 'Certificate Decoder', href: '/certificate-decoder' },
  //     { title: 'Protobuf', href: '/protobuf' },
  //   ],
  // },
  // {
  //   title: 'Security',
  //   links: [
  //     { title: 'Java Decompiler', href: '/security/java-decompiler' },
  //     { title: 'APK Decompiler', href: '/security/apk-decompiler' },
  //   ],
  // },
]

export function Navigation(props) {
  return (
    <nav {...props}>
      <ul>
        {navigation.map((group, groupIndex) => (
          <NavigationGroup
            key={group.title}
            group={group}
            className={groupIndex === 0 && 'md:mt-0'}
          />
        ))}
        <li className="sticky bottom-0 z-10 mt-6 text-xs text-zinc-600 dark:text-zinc-400 text-center">
          v{packageJson.version} &copy; {new Date().getFullYear()}
        </li>
      </ul>
    </nav>
  )
}
